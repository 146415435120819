












































































































































































































































import {
  InvoicePostingModal,
  InvoicePrintReceiptModal,
  InvoiceSummaryModal,
} from "@/components/InvoiceAr";
import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import { trimSpaceToUnderscore } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import {
  APagination,
  useBranch,
  useContactData,
  useFindMasterType,
  useMapMasterTypeToOptionAlt,
  useTruckingAccountReceivable,
} from "@/hooks";
import MNotification from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  InvoiceArListDto,
  InvoicePostingDataView,
} from "@/models/interface/account-receivable";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { TruckingAccountReceivableFormFilterState } from "@interface/trucking-account-receivable";
import { FormModel } from "ant-design-vue";
import moment from "moment";
import Vue from "vue";

export default Vue.extend({
  name: "IndexPage",
  components: {
    InvoiceSummaryModal,
    InvoicePostingModal,
    InvoicePrintReceiptModal,
  },
  mixins: [MNotification],
  data() {
    this.onSearchBranch = debounceProcess(this.onSearchBranch, 500);
    this.onSearchCustomer = debounceProcess(this.onSearchCustomer, 500);
    this.onSearchInvoice = debounceProcess(this.onSearchInvoice, 500);
    return {
      DEFAULT_DATE_FORMAT,
      PAGE_SIZE_OPTIONS,
      pagination: {
        page: DEFAULT_PAGE,
        limit: DEFAULT_PAGE_SIZE,
      },
      formState: {
        branch: undefined,
        customer: undefined,
        invoiceDate: null,
        invoice: undefined,
        status: undefined,
      } as TruckingAccountReceivableFormFilterState,
      form: this.$refs.form as FormModel,
      branchOptions: [] as Option[],
      customerOptions: [] as Option[],
      invoiceOptions: [] as Option[],
      statusOptions: [] as Option[],
      selectedRowKeys: [] as string[],
      selectedInvoices: [] as InvoicePostingDataView[],
      modalSummary: {
        show: false,
        invoiceId: "",
      },
      modalPrint: {
        show: false,
        data: [] as string[],
      },
      modalPosting: {
        show: false,
        data: [] as InvoicePostingDataView[],
      },
      loading: {
        branch: false,
        customer: false,
        invoice: false,
        status: false,
        find: false,
        posting: false,
        print: false,
      },
      invoices: {
        data: [],
        totalElements: 0,
        currentPage: 0,
        totalPages: 0,
      } as Pagination<InvoiceArListDto>,
      columns: [
        {
          title: this.$t("lbl_invoice_date"),
          dataIndex: "invoiceDate",
          width: 150,
          customRender: (text: string) => dateFormat(text),
        },
        {
          title: this.$t("lbl_invoice_number"),
          dataIndex: "documentNumber",
          width: 200,
          scopedSlots: { customRender: "documentNumber" },
        },
        {
          title: this.$t("lbl_fp_no"),
          dataIndex: "taxInvoiceNumber",
          width: 150,
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_cust_code"),
          dataIndex: "custCode",
          width: 130,
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_customer_name"),
          dataIndex: "customerName",
          width: 300,
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_dpp"),
          dataIndex: "dpp",
          width: 150,
          customRender: (text: number) => currencyFilter(text),
        },
        {
          title: this.$t("lbl_vat"),
          dataIndex: "vat",
          width: 150,
          customRender: (text: number) => currencyFilter(text),
        },
        {
          title: this.$t("lbl_total_amount"),
          dataIndex: "grandTotal",
          width: 150,
          customRender: (text: number) => currencyFilter(text),
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "status",
          width: 130,
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_journal_number"),
          dataIndex: "journalNo",
          width: 130,
          scopedSlots: { customRender: "journal" },
        },
        {
          title: this.$t("lbl_action"),
          key: "action",
          scopedSlots: { customRender: "action" },
          width: 120,
          align: "center",
        },
      ],
    };
  },
  mounted() {
    this.getBranchList();
    this.getCustomerList();
    this.getInvoiceList();
    this.getStatusList();

    this.form = this.$refs.form as FormModel;

    const params = new RequestQueryParams();
    params.sorts = "createdDate:desc";
    this.getInvoiceTruckingList(params);
  },
  methods: {
    getRoute(status: string) {
      const editable = ["DRAFT", "UNPAID", "NEED_APPROVAL", "DELIVERED"];
      if (
        editable.includes(trimSpaceToUnderscore(status)) &&
        (this.$ability.can("create", "trucking-invoice-ar") ||
          this.$ability.can("update", "trucking-invoice-ar") ||
          this.$ability.can("cancel", "trucking-invoice-ar"))
      ) {
        return "trucking.account-receivable.edit";
      }
      return "trucking.account-receivable.detail";
    },
    async getInvoiceTruckingList(params?: RequestQueryParamsModel) {
      const { findAll } = useTruckingAccountReceivable();
      try {
        this.loading.find = true;
        const response = await findAll(params);
        this.invoices = response;
      } finally {
        this.loading.find = false;
      }
    },
    onSubmit(): void {
      const params = new RequestQueryParams();
      params.search = this.buildSearch(this.formState);
      params.sorts = "createdDate:desc";
      this.getInvoiceTruckingList(params);
    },
    handleReset() {
      this.form.resetFields();
      this.getBranchList();
      this.getCustomerList();
      this.getInvoiceList();
    },
    async getBranchList(params?: RequestQueryParamsModel) {
      const { findAll, toOptions } = useBranch();
      this.loading.branch = true;
      const response = await findAll(params);
      this.branchOptions = toOptions(response.data);
      this.loading.branch = false;
    },
    onSearchBranch(value?: string) {
      const { searchBy } = useBranch();
      const params = new RequestQueryParams();
      if (value) {
        params.search = searchBy({ name: value });
      }
      this.getBranchList(params);
    },
    async getCustomerList(
      params: RequestQueryParamsModel = new RequestQueryParams()
    ) {
      const { findCustomers, toOptions } = useContactData();
      try {
        this.loading.customer = true;
        const response = await findCustomers(params);
        this.customerOptions = toOptions(response.data);
      } finally {
        this.loading.customer = false;
      }
    },
    onSearchCustomer(value?: string) {
      const { filterBy } = useContactData();
      const params = new RequestQueryParams();
      if (value) {
        params.search = filterBy({ firstName: value, lastName: value });
      }
      this.getCustomerList(params);
    },
    async getInvoiceList(params?: RequestQueryParamsModel) {
      const { findAll, toOptions } = useTruckingAccountReceivable();
      try {
        this.loading.invoice = true;
        const response = await findAll(params);
        this.invoiceOptions = toOptions(response.data);
      } finally {
        this.loading.invoice = false;
      }
    },
    onSearchInvoice(value?: string) {
      const { buildSearch } = useTruckingAccountReceivable();
      const params = new RequestQueryParams();
      if (value) {
        params.search = buildSearch({ invoice: value });
      }
      this.getInvoiceList(params);
    },
    async getStatusList() {
      try {
        this.loading.status = true;
        const response = await useFindMasterType("INVOICE_AR_STATUS");
        this.statusOptions = useMapMasterTypeToOptionAlt(response);
      } finally {
        this.loading.status = false;
      }
    },
    onChangeTable(pagination: APagination): void {
      const { current, pageSize } = pagination;

      this.pagination.page = current;

      if (pageSize !== this.pagination.limit) {
        this.pagination.page = DEFAULT_PAGE;
      }

      this.pagination.limit = pageSize;

      const params = new RequestQueryParams();
      params.search = this.buildSearch(this.formState);
      params.limit = this.pagination.limit;
      params.page = this.pagination.page - ONE;
      params.sorts = "createdDate:desc";
      this.getInvoiceTruckingList(params);
    },
    viewSummary(invoiceId: string) {
      this.modalSummary.invoiceId = invoiceId;
      this.modalSummary.show = true;
    },
    buildSearch(state: TruckingAccountReceivableFormFilterState) {
      const { buildSearch: searchBy } = useTruckingAccountReceivable();
      return searchBy({
        branch: state.branch?.key,
        customer: state.customer?.key,
        invoiceDate: state.invoiceDate,
        invoiceId: state.invoice?.key,
        status: state.status,
      });
    },
    handlePosting(invoices: InvoicePostingDataView[]) {
      this.modalPosting.show = true;
      this.modalPosting.data = [...invoices];
    },
    onSelectChange(values: string[]) {
      this.selectedRowKeys = values;
    },
    onSelectToggle(record: InvoiceArListDto, selected: boolean) {
      if (selected) {
        const dataView: InvoicePostingDataView = {
          customerName: record.customerName,
          invoiceDate: moment(record.invoiceDate),
          invoiceId: record.id,
          status: record.status,
          branchId: record.branchId,
        };
        this.selectedInvoices.push(dataView);
      } else {
        const index = this.selectedInvoices.findIndex(
          item => item.invoiceId === record.id
        );
        if (index >= 0) {
          this.selectedInvoices.splice(index, 1);
        }
      }
    },
    onSelectAllRecord(selected: boolean, records: InvoiceArListDto[]) {
      this.selectedInvoices = [];

      if (selected) {
        for (const record of records) {
          const dataView: InvoicePostingDataView = {
            customerName: record.customerName,
            invoiceDate: moment(record.invoiceDate),
            invoiceId: record.id,
            status: record.status,
            branchId: record.branchId,
          };
          this.selectedInvoices.push(dataView);
        }
      }
    },
    handleApproved() {
      const params = new RequestQueryParams();
      params.search = this.buildSearch(this.formState);
      params.limit = this.pagination.limit;
      params.page = this.pagination.page - ONE;
      params.sorts = "createdDate:desc";
      this.getInvoiceTruckingList(params);
    },
    validatePosting() {
      // only allow posting from one customer
      // and status only delivered or need approval

      const customers = new Set<string>();
      for (const doc of this.selectedInvoices) {
        customers.add(doc.customerName);
      }

      const isAllowedStatus = this.selectedInvoices.every(doc => {
        return (
          trimSpaceToUnderscore(doc.status) === "DELIVERED" ||
          trimSpaceToUnderscore(doc.status) === "NEED_APPROVAL"
        );
      });

      if (customers.size > 1 || !isAllowedStatus) {
        this.showNotifError("notif_invoice_posting_invalid");
      } else {
        this.handlePosting(this.selectedInvoices);
      }
    },
    handlePrintReceipt() {
      this.modalPrint.show = true;
      this.modalPrint.data = this.selectedInvoices.map(item => item.invoiceId);
    },
    validatePrintReceipt() {
      const branches = new Set<string>();
      const customers = new Set<string>();

      for (const invoice of this.selectedInvoices) {
        branches.add(invoice.branchId);
        customers.add(invoice.customerName);
      }

      if (branches.size > 1 || customers.size > 1) {
        this.showNotifWarning("notif_invalid_ar_print_receipt");
        return;
      }

      this.handlePrintReceipt();
    },
    isNotAllowToPrintReceipt(status: string): boolean {
      const allow = [
        "NEED_APPROVAL",
        "DELIVERED",
        "REJECTED",
        "CANCELLED",
        "UNPAID",
        "PARTIAL_PAID",
        "FULLY_PAID",
        "RETURNED",
      ];
      return !allow.includes(trimSpaceToUnderscore(status));
    },
  },
});
